import { Link } from 'react-router-dom'

import { OrganizationsList } from '../components/OrganizationsList'

export function OrganizationsPage() {
  return (
    <div>
      <h1>Organization page</h1>

      <Link to="/organizations/create">Create organization</Link>

      <OrganizationsList />
    </div>
  )
}
