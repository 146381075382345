import { apiBusinessClient, base64ToUrl, callApi, fileToBase64, useApi } from '@petr-jilek/letssport-common'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export function EventDetailPage() {
  const { nameUrl } = useParams()

  const {
    loading: eventDetailLoading,
    response: eventDetailResponse,
    apiCall: eventDetailApiCall
  } = useApi({
    method: useCallback(() => apiBusinessClient.api.eventsGetEventDetailGET(nameUrl ?? ''), [nameUrl]),
    callOnMount: true
  })

  const setEventImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return

    const file = e.target.files[0]
    const data = await fileToBase64(file)

    const event = eventDetailResponse?.data?.event

    if (!event?.id) return

    const response = await callApi(() =>
      apiBusinessClient.api.eventsUploadEventImagePOST(event.id!, {
        fileName: 'img',
        fileData: data as string
      })
    )

    if (response.ok) {
      toast.success('Image uploaded')
      eventDetailApiCall()
    }
  }

  if (eventDetailLoading) return <p>Loading...</p>

  if (!eventDetailResponse?.data?.event) return <p>Event not found</p>

  const eventDetail = eventDetailResponse?.data
  const event = eventDetail.event!
  const image = eventDetail.image
  const files = eventDetail.files

  return (
    <div>
      <h1>{event.name}</h1>

      <input type="file" onChange={setEventImage} />
      <br />

      {image && <img src={base64ToUrl(image)} alt={event.name} style={{ width: '200px' }} />}
      <p>{event.description}</p>
      <p>{event.date}</p>
      <p>{event.location}</p>

      {files && (
        <div>
          {files.map((file) => (
            <div key={file.fileName}>
              <a
                href={`data:application/pdf;base64, ${file.fileData}`}
                download={file.fileName}
                target="_blank"
                rel="noreferrer"
              >
                {file.fileName}
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
