import { apiBusinessClient, useApi } from '@petr-jilek/letssport-common'

import { OrganizationTile } from './OrganizationTile'

export function OrganizationsList() {
  const { loading, response } = useApi({
    method: apiBusinessClient.api.organizationsGetOrganizationsGET,
    callOnMount: true
  })

  if (loading) return <p>Loading...</p>
  if (!response.ok || !response.data) return <p>Error</p>

  const organizations = response.data.items

  return (
    <div>
      {organizations.map((organizationWithUserRelation) => (
        <OrganizationTile
          key={organizationWithUserRelation.organization.id}
          organizationWithUserRelation={organizationWithUserRelation}
        />
      ))}
    </div>
  )
}
