import { apiBusinessClient, callApi, useApi } from '@petr-jilek/letssport-common'
import { CreateOrganizationDto } from '@petr-jilek/letssport-common/dist/api/vendor/apiBusiness'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { HTMLFormControlsCollectionExtended } from '../../../shared/polyfillTypes'

export function OrganizationCreateUpdatePage() {
  const { nameUrl } = useParams()

  const navigate = useNavigate()

  const { loading, response } = useApi({
    method: () => apiBusinessClient.api.organizationsGetOrganizationDetailGET(nameUrl ?? ''),
    callOnMount: !!nameUrl
  })

  const [sumbitting, setSubmitting] = useState(false)

  const submit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSubmitting(true)

    const elements = e.currentTarget.elements as HTMLFormControlsCollectionExtended

    const name = elements.getValue('name')
    const description = elements.getValue('description')

    if (nameUrl && response.ok && response.data) {
      const organization = response.data.organization

      const updateRequest: CreateOrganizationDto = {
        name,
        description
      }

      const updateResponse = await callApi(() =>
        apiBusinessClient.api.organizationsUpdateOrganizationPUT(organization.id, updateRequest)
      )

      if (updateResponse.ok) {
        toast.success('Organization updated')
        redirectToOrganizations()
      }
    } else {
      const createRegues: CreateOrganizationDto = {
        name,
        description
      }

      const createResponse = await callApi(() =>
        apiBusinessClient.api.organizationsCreateOrganizationPOST(createRegues)
      )

      if (createResponse.ok) {
        toast.success('Organization created')
        redirectToOrganizations()
      }
    }

    setSubmitting(false)
  }

  const redirectToOrganizations = () => {
    navigate('/organizations')
  }

  if (loading) return <p>Loading...</p>
  if (nameUrl && (!response.ok || !response.data)) return <p>Error</p>

  const organization = response?.data?.organization

  return (
    <div>
      <h1>Organization Create Update Page</h1>

      <form onSubmit={submit}>
        <input type="text" name="name" placeholder="Name" required defaultValue={organization?.name ?? ''} />
        <br />

        <textarea
          name="description"
          placeholder="Description"
          required
          defaultValue={organization?.description ?? ''}
        ></textarea>
        <br />

        <button type="submit" disabled={sumbitting}>
          Submit
        </button>
      </form>
    </div>
  )
}
