import { apiBusinessClient, callApi, useApi } from '@petr-jilek/letssport-common'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

export function EventsPage() {
  const {
    loading: eventsLoading,
    response: eventsResponse,
    apiCall: eventsApiCall
  } = useApi({
    method: apiBusinessClient.api.eventsGetCreatedEventsAsSelfGET,
    callOnMount: true
  })

  const [deleteEventProcessing, setDeleteEventProcessing] = useState(false)

  const deleteEvent = async (eventId: string) => {
    setDeleteEventProcessing(true)

    var deleteEventResponse = await callApi(() => apiBusinessClient.api.eventsDeleteEventDELETE(eventId))
    if (deleteEventResponse.ok) {
      toast.success('Event deleted')
    }

    eventsApiCall()

    setDeleteEventProcessing(false)
  }

  if (eventsLoading) return <p>Loading...</p>

  if (!eventsResponse.ok || !eventsResponse.data) return <p>Error</p>

  const events = eventsResponse.data.items ?? []

  return (
    <div>
      <h1>Events page</h1>

      <Link to="/events/create">Add event</Link>

      <div>
        {events.map((event) => (
          <div
            key={event.id}
            style={{
              padding: '10px',
              border: '1px solid black'
            }}
          >
            <h2>{event.name}</h2>
            <p>{event.description}</p>
            <p>{event.date}</p>
            <p>{event.location}</p>

            <Link to={`/events/detail/${event.nameUrl}`}>Detail</Link>
            <br />

            <Link to={`/events/update/${event.nameUrl}`}>Edit</Link>
            <br />

            <button onClick={() => deleteEvent(event.id ?? '')} disabled={deleteEventProcessing}>
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}
