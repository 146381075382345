import { apiApiClient, useApi } from '@petr-jilek/letssport-common'

import { LogoutButton } from '../../../shared/components/LogoutButton'

export function RequestAccessPage() {
  const {
    loading: requestAccessLoading,
    apiCall: requestAccessApiCall,
    done: requestAccessDone
  } = useApi({
    method: apiApiClient.api.accessibilityRequestUserBusinessClaimPOST
  })

  return (
    <div>
      <h1>Request Access Page</h1>

      <LogoutButton />

      <p>Request access to the platform</p>

      {requestAccessDone ? (
        <p>Request sent</p>
      ) : (
        <button onClick={requestAccessApiCall} disabled={requestAccessLoading}>
          Request access
        </button>
      )}
    </div>
  )
}
