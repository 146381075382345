import { apiBusinessClient, base64ToUrl, callApi, fileToBase64, useApi } from '@petr-jilek/letssport-common'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export function OrganizationDetailPage() {
  const { nameUrl } = useParams()

  const { loading, response, apiCall } = useApi({
    method: () => apiBusinessClient.api.organizationsGetOrganizationDetailGET(nameUrl ?? ''),
    callOnMount: true
  })

  const [profilePictureUploading, setProfilePictureUploading] = useState(false)

  const uploadOrganizationProfilePicture = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setProfilePictureUploading(true)

    if (!e.target.files?.length) return

    const file = e.target.files[0]
    const data = await fileToBase64(file)

    const organization = response.data?.organization
    if (!organization?.id) return

    const uploadResponse = await callApi(() =>
      apiBusinessClient.api.organizationsUploadOrganizationProfilePicturePOST(organization.id, {
        fileName: 'img',
        fileData: data as string
      })
    )

    if (uploadResponse.ok) {
      toast.success('Profile Picture Uploaded')
      apiCall()
    }

    setProfilePictureUploading(false)
  }

  if (loading) return <p>Loading...</p>
  if (!response.ok || !response.data) return <p>Error</p>

  const data = response.data
  const organization = data.organization
  const organizationUserRelation = data.organizationUserRelation
  const profilePicture = data.profilePicture

  return (
    <div>
      <h1>{organization.name}</h1>

      {profilePicture && (
        <img src={base64ToUrl(profilePicture)} alt={organization.nameUrl} style={{ width: '200px' }} />
      )}
      <br />

      <input type="file" onChange={uploadOrganizationProfilePicture} disabled={profilePictureUploading} />

      <p>{organization.description}</p>
      <p>{organizationUserRelation.relationType}</p>
    </div>
  )
}
