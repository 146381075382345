import { apiAuthClient, useApi } from '@petr-jilek/letssport-common'
import { Link, Outlet, useNavigate } from 'react-router-dom'

import { LogoutButton } from '../../shared/components/LogoutButton'

export function HomeView() {
  const navigate = useNavigate()

  const { loading: userLoading, response: userResponse } = useApi({
    method: apiAuthClient.api.usersGetUserInfoGET,
    callbackBeforeStopLoading: async (response) => {
      if (!response.data?.claims?.includes('Business')) {
        navigate('/auth/request-access')
      }
    },
    callOnMount: true,
    initLoading: true
  })

  if (userLoading) return <p>Loading...</p>

  if (!userResponse.ok || !userResponse.data) return <p>Error</p>

  const user = userResponse.data

  return (
    <div>
      <nav>
        <p>{user.email}</p>
        <LogoutButton />

        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/events">Events</Link>
          </li>
          <li>
            <Link to="/leagues">Leagues</Link>
          </li>
          <li>
            <Link to="/organizations">Organizations</Link>
          </li>
        </ul>
      </nav>

      <Outlet />
    </div>
  )
}
