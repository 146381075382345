import { base64ToUrl } from '@petr-jilek/letssport-common'
import { GetOrganizationDetailResponse } from '@petr-jilek/letssport-common/dist/api/vendor/apiBusiness'

export interface OrganizationTileProps {
  organizationWithUserRelation: GetOrganizationDetailResponse
}

export function OrganizationTile({ organizationWithUserRelation }: OrganizationTileProps) {
  const organization = organizationWithUserRelation.organization
  const organizationUserRelation = organizationWithUserRelation.organizationUserRelation
  const profilePicture = organizationWithUserRelation.profilePicture

  return (
    <div
      key={organization.id}
      style={{
        margin: '10px 0',
        padding: '10px',
        border: '1px solid black'
      }}
    >
      <h2>{organization.name}</h2>
      {profilePicture && (
        <img src={base64ToUrl(profilePicture)} alt={organization.nameUrl} style={{ width: '200px' }} />
      )}
      <br />
      <p>{organization.description}</p>
      <p>{organizationUserRelation.relationType}</p>
      <a href={`/organizations/detail/${organization.nameUrl}`}>Detail</a>
      <br />
      <a href={`/organizations/update/${organization.nameUrl}`}>Edit</a>
    </div>
  )
}
