import { apiBusinessClient, callApi, useApi } from '@petr-jilek/letssport-common'
import { CreateEventDto } from '@petr-jilek/letssport-common/dist/api/vendor/apiBusiness'
import { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { HTMLFormControlsCollectionExtended } from '../../../shared/polyfillTypes'

export function EventsCreateUpdatePage() {
  const { nameUrl } = useParams()

  const navigate = useNavigate()

  const { loading: eventLoading, response: eventResponse } = useApi({
    method: useCallback(() => apiBusinessClient.api.eventsGetEventDetailGET(nameUrl ?? ''), [nameUrl]),
    callOnMount: !!nameUrl
  })

  const [sumbitting, setSubmitting] = useState(false)

  const submit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSubmitting(true)

    const elements = e.currentTarget.elements as HTMLFormControlsCollectionExtended

    const name = elements.getValue('name')
    const date = elements.getValue('date')
    const location = elements.getValue('location')
    const description = elements.getValue('description')

    const data: CreateEventDto = {
      name,
      date,
      location,
      description,
      sport: 'Cycling',
      type: 'Road'
    }

    const method = nameUrl
      ? () => apiBusinessClient.api.eventsUpdateEventPUT(eventResponse.data?.event.id ?? '', data)
      : () => apiBusinessClient.api.eventsCreateEventAsSelfPOST(data)

    const response = await callApi(method)

    if (response.ok) {
      if (nameUrl) toast.success('Event updated')
      else toast.success('Event created')
      navigate('/events')
    }

    setSubmitting(false)
  }

  if (eventLoading) return <p>Loading...</p>
  //   if (nameUrl && (!response.ok || !response.data)) return <p>Error</p>

  const event = eventResponse?.data?.event

  return (
    <div>
      <h1>Events Add Edit Page</h1>

      <form onSubmit={submit}>
        <input type="text" name="name" placeholder="Name" required defaultValue={event?.name ?? ''} />
        <br />

        <input
          type="date"
          name="date"
          required
          defaultValue={event?.date ? new Date(event.date).toISOString().split('T')[0] : ''}
        />
        <br />

        <input type="text" name="location" placeholder="Location" required defaultValue={event?.location ?? ''} />
        <br />

        <textarea
          name="description"
          placeholder="Description"
          required
          defaultValue={event?.description ?? ''}
        ></textarea>
        <br />

        <button type="submit" disabled={sumbitting}>
          Submit
        </button>
      </form>
    </div>
  )
}
